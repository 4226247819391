import MasksABI from '@/files/ERC721.abi';
import DoubleDropABI from '@/files/DoubleDrop.abi';
import DerivativesABI from '@/files/Derivatives.abi';
import ElementalsABI from '@/files/Elementals.abi';
import NCTABI from '@/files/ERC20.abi';
import {
  HASHMASKS_CONTRACT_ADDRESS,
  NCT_CONTRACT_ADDRESS,
  DOUBLEDROP_CONTRACT_ADDRESS,
  ELEMENTALS_CONTRACT_ADDRESS,
  DERIVATIVES_CONTRACT_ADDRESS,
  RPC_URL,
  STARTING_INDEX,
  GRAVEYARD_ADDRESS,
} from '@/assets/constants';
import { ethers } from 'ethers';

const rpcProvider = new ethers.providers.JsonRpcProvider(RPC_URL);
const web3Provider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum)
  : undefined;

const hashmasksContract = new ethers.Contract(
  HASHMASKS_CONTRACT_ADDRESS,
  MasksABI,
);

const doubleDropContract = new ethers.Contract(
  DOUBLEDROP_CONTRACT_ADDRESS,
  DoubleDropABI,
);

const derivativesContract = new ethers.Contract(
  DERIVATIVES_CONTRACT_ADDRESS,
  DerivativesABI,
);

const elementalsContract = new ethers.Contract(
  ELEMENTALS_CONTRACT_ADDRESS,
  ElementalsABI,
);

const nctContract = new ethers.Contract(NCT_CONTRACT_ADDRESS, NCTABI);

const getWriteProvider = async () => {
  var provider = web3Provider;
  try {
    await provider.getSigner().getAddress();
    return provider;
  } catch {
    return undefined;
  }
};

const getReadProvider = async () => {
  var provider = web3Provider;
  try {
    await provider.getSigner().getAddress();
  } catch {
    provider = rpcProvider;
  }
  return provider;
};

const getEthAddress = async () => {
  var provider = web3Provider;
  try {
    return await provider.getSigner().getAddress();
  } catch {
    return undefined;
  }
};

const hashmasksBalanceOf = async (address) => {
  const provider = await getReadProvider();
  const defaultAccount = await getEthAddress();
  const currentlyOwned = await hashmasksContract
    .connect(provider)
    .balanceOf(address || defaultAccount);
  return currentlyOwned?.toNumber() ?? 0;
};

const elementalsBalanceOf = async (address) => {
  const provider = await getReadProvider();
  const defaultAccount = await getEthAddress();
  const currentlyOwned = await elementalsContract
    .connect(provider)
    .balanceOf(address || defaultAccount);
  return currentlyOwned?.toNumber() ?? 0;
};

const derivativesBalanceOf = async (address) => {
  const provider = await getReadProvider();
  const defaultAccount = await getEthAddress();
  const currentlyOwned = await derivativesContract
    .connect(provider)
    .balanceOf(address || defaultAccount);
  return currentlyOwned?.toNumber() ?? 0;
};

const elementalsSupply = async () => {
  const provider = await getReadProvider();
  const supply = (
    await elementalsContract.connect(provider).totalSupply()
  ).toNumber();
  return supply;
};

const derivativesSupply = async () => {
  const provider = await getReadProvider();
  const supply = (
    await derivativesContract.connect(provider).totalSupply()
  ).toNumber();
  return supply;
};

const nctBalanceOf = async (address) => {
  const provider = await getReadProvider();
  const defaultAccount = await getEthAddress();
  const balance = await nctContract
    .connect(provider)
    .balanceOf(address || defaultAccount);
  return balance; //Must stay big number
};

const tokenOfOwnerByIndex = async (index, address) => {
  const provider = await getReadProvider();
  const defaultAccount = await getEthAddress();
  const token = await hashmasksContract
    .connect(provider)
    .tokenOfOwnerByIndex(address || defaultAccount, index);
  return token?.toNumber() ?? undefined;
};

const getBurnedHashmaskIds = async () => {
  const address = GRAVEYARD_ADDRESS;
  const balance = await hashmasksBalanceOf(address);
  return await Promise.all(
    [...Array(balance)].map(async (_, index) => {
      return await tokenOfOwnerByIndex(index, address);
    }),
  );
};

const isClaimActive = async () => {
  const provider = await getReadProvider();
  const isRedeemed = await doubleDropContract.connect(provider).isActive();
  return isRedeemed;
};

const isRedeemedForTokenId = async (tokenId) => {
  const provider = await getReadProvider();
  const isRedeemed = await doubleDropContract
    .connect(provider)
    .redeemedHashmasks(tokenId);
  return isRedeemed;
};

const redemptionType = async (tokenId) => {
  const provider = await getReadProvider();

  const owner = await getNFTOwner(tokenId);
  const graveyard = ethers.utils.getAddress(GRAVEYARD_ADDRESS);
  if (owner === graveyard) {
    return 'Burned';
  }

  const derivativeExists = await derivativesContract
    .connect(provider)
    .exists(tokenId);
  if (derivativeExists) {
    return 'Derivative';
  }

  const elementalExists = await elementalsContract
    .connect(provider)
    .exists(tokenId);
  if (elementalExists) {
    return 'Elemental';
  }
  return undefined;
};

const accumulatedForIndex = async (index) => {
  const provider = await getReadProvider();
  const accumulated = await nctContract.connect(provider).accumulated(index);
  return accumulated; // BigNumber
};

const claimNCT = async (indices) => {
  const provider = await getWriteProvider();
  return await nctContract.connect(provider.getSigner()).claim(indices);
};

const changeNFTName = async (index, newName) => {
  const provider = await getWriteProvider();
  return await hashmasksContract
    .connect(provider.getSigner())
    .changeName(index, newName);
};

const getNFTName = async (index) => {
  const provider = await getReadProvider();
  return await hashmasksContract.connect(provider).tokenNameByIndex(index);
};

const isNameReserved = async (name) => {
  const provider = await getReadProvider();
  return await hashmasksContract.connect(provider).isNameReserved(name);
};

const getNFTOwner = async (index) => {
  const provider = await getReadProvider();
  return await hashmasksContract.connect(provider).ownerOf(index);
};

const toEthUnit = (wei) => {
  return ethers.utils.formatEther(wei);
};

const getTransactionReceipt = async (hash) => {
  const provider = await getReadProvider();
  return await provider.getTransactionReceipt(hash);
};

const waitForTransaction = async (tx) => {
  const provider = await getReadProvider();
  return await provider.waitForTransaction(tx);
};

const getTimestampFromBlock = async (hash) => {
  const provider = await getReadProvider();
  const receipt = await provider.getBlock(hash);
  return receipt.timestamp;
};

const getRevealedMaskIndex = function(nftIndex) {
  return (Number(nftIndex) + STARTING_INDEX) % 16384;
};

const isValidETHAddress = (address) => {
  try {
    ethers.utils.getAddress(address);
    return true;
  } catch {
    return false;
  }
};

const generateSignature = async (dataToSign) => {
  const provider = await getWriteProvider();
  return await provider.getSigner().signMessage(dataToSign);
};

const isApprovedForTransfer = async (tokenId) => {
  const provider = await getReadProvider();
  const approved = await hashmasksContract
    .connect(provider)
    .getApproved(tokenId);
  return (
    ethers.utils.getAddress(approved) ===
    ethers.utils.getAddress(DOUBLEDROP_CONTRACT_ADDRESS)
  );
};

const isApprovedForAll = async () => {
  const provider = await getReadProvider();
  const address = await getEthAddress();
  const approved = await hashmasksContract
    .connect(provider)
    .isApprovedForAll(address, DOUBLEDROP_CONTRACT_ADDRESS);
  return approved;
};

const performTransferApproval = async (tokenId) => {
  const provider = await getWriteProvider();
  return await hashmasksContract
    .connect(provider.getSigner())
    .approve(DOUBLEDROP_CONTRACT_ADDRESS, tokenId);
};

const performApprovalForAll = async () => {
  const provider = await getWriteProvider();
  return await hashmasksContract
    .connect(provider.getSigner())
    .setApprovalForAll(DOUBLEDROP_CONTRACT_ADDRESS, true);
};

const redeemDerivatives = async (signature, tokenIds) => {
  const provider = await getWriteProvider();
  const sorted = tokenIds.sort();
  return await doubleDropContract
    .connect(provider.getSigner())
    .redeemDerivatives(signature, sorted);
};

const redeemElementals = async (signature, tokenIds) => {
  const provider = await getWriteProvider();
  const sorted = tokenIds.sort();
  return await doubleDropContract
    .connect(provider.getSigner())
    .redeemElementals(signature, sorted);
};

const burnMasksForDoubleRedemption = async (signature, tokenIds) => {
  const provider = await getWriteProvider();
  const sorted = tokenIds.sort();
  return await doubleDropContract
    .connect(provider.getSigner())
    .burnMasksForDoubleRedemption(signature, sorted);
};

export {
  burnMasksForDoubleRedemption,
  redeemElementals,
  redeemDerivatives,
  getEthAddress,
  hashmasksBalanceOf,
  derivativesBalanceOf,
  elementalsBalanceOf,
  tokenOfOwnerByIndex,
  accumulatedForIndex,
  toEthUnit,
  claimNCT,
  changeNFTName,
  getNFTName,
  isNameReserved,
  getNFTOwner,
  nctBalanceOf,
  getTransactionReceipt,
  getTimestampFromBlock,
  getRevealedMaskIndex,
  isValidETHAddress,
  isApprovedForTransfer,
  isApprovedForAll,
  generateSignature,
  isRedeemedForTokenId,
  performTransferApproval,
  performApprovalForAll,
  waitForTransaction,
  getBurnedHashmaskIds,
  redemptionType,
  derivativesSupply,
  elementalsSupply,
  isClaimActive,
};
